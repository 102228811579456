<div class="links-container footer-links" [ngClass]="{'small-font': isSmallFont}">
  <ul id="linkMenu" class="list-inline">
    <li><a href="#" routerLink="/tos" target="_blank">Terms Of Use</a></li>
    <li><a href="{{PortalAccessAgreementUrl}}" target="_blank"> Website Privacy Policy</a></li>
    <li><a href="{{PortalAccessPolicyUrl}}" target="_blank"> Provider Portal Policy</a></li>
    <li *ngIf="IsLoggedInUser"><a href="#" routerLink="/dashboard/mof"> Medical Director Contact Information</a></li>        
  </ul>
</div>
<div class="links-container footer-links" [ngClass]="{'small-font': isSmallFont}">
  <ul id="linkMenu" class="list-inline">
    <li><a href="{{caloptimahealthguidelinesUrl}}" target="_blank"> CalOptima Health Guidelines</a></li>
  </ul>
</div>


