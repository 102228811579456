import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders, HttpResponse } from '@angular/common/http';
import { OAuthService } from 'angular-oauth2-oidc';
import { PortalConfig } from '../portal-config';
import { AppConfig, HttpResponseHelper } from '@caloptima/portal-foundation';
import { Observable, of } from 'rxjs';
import { distinctUntilChanged, switchMap, map, catchError } from 'rxjs/operators';
import { ReferralSubmissionResponse, WellnessSubmissionResponse } from './models/referral-submission-response';
import { ReferralAttachment, ReferralAttachmentFile } from './models/referral-attachment';
import { ADTAttachmentFile, ADTSearchData, WellnessAttachment, WellnessAttachmentData, WellnessAttachmentFile, WellnessSearchData } from './models/wellness-attachment';
import { AdtSubmissionResponse } from './models/adt-submission-response';

@Injectable({
  providedIn: 'root'
})
export class FileService {
  private baseProviderFileServiceUrl: string;
  private postReferralAttachmentUrl: string;
  private getReferralAttachmentUrl: string;
  private getReferralAttachmentAndFilesUrl: string;
  private getReferralAttachmentAndFilesExtUrl: string;
  private getReferralAttachmentFilesUrl: string;
  private getReferralAttachmentFileUrl: string;
  private getReferralAttachmentExtFileUrl: string;
  private postWellnessAttachmentUrl: string;
  private getWellnessAttachmentUrl: string;
  private getWellnessAttachmentAndFilesUrl: string;
  private getWellnessAttachmentFilesUrl: string;
  private getWellnessAttachmentFileUrl: string;  
  private postADTAttachmentUrl: string;
  private getADTAttachmentUrl: string;
  private getADTAttachmentAndFilesUrl: string;
  private getADTAttachmentFilesUrl: string;
  private getADTAttachmentFileUrl: string;  

  constructor(
    private authService: OAuthService,
    private http: HttpClient,
    private portalConfig: PortalConfig,
    private appConfig: AppConfig) {
    this.baseProviderFileServiceUrl = appConfig.getConfig('BaseProviderFileServiceApiUrl');
    if (this.baseProviderFileServiceUrl == null) {
      const config$ = appConfig.subscribe(() => {
        this.baseProviderFileServiceUrl = appConfig.getConfig('BaseProviderFileServiceApiUrl');
        this.checkUrls();
        config$.unsubscribe();
      });
    }
  }

  private checkUrls(): void {
    const baseReferralUrl = this.baseProviderFileServiceUrl + 'api/ReferralAttachment';

    if (this.postReferralAttachmentUrl == null) {
      this.postReferralAttachmentUrl = baseReferralUrl;
    }
    if (this.getReferralAttachmentUrl == null) {
      this.getReferralAttachmentUrl = baseReferralUrl + '/search';
    }
    if (this.getReferralAttachmentAndFilesUrl == null) {
      this.getReferralAttachmentAndFilesUrl = baseReferralUrl + '/search/files';
    }
    if (this.getReferralAttachmentAndFilesExtUrl == null) {
      this.getReferralAttachmentAndFilesExtUrl = baseReferralUrl + '/search/files/ext';
    }
    if (this.getReferralAttachmentFilesUrl == null) {
      this.getReferralAttachmentFilesUrl = baseReferralUrl + '/data';
    }
    if (this.getReferralAttachmentFileUrl == null) {
      this.getReferralAttachmentFileUrl = baseReferralUrl + '/file';
    }
    if (this.getReferralAttachmentExtFileUrl == null) {
      this.getReferralAttachmentExtFileUrl = baseReferralUrl + '/file/ext';
    }
    
    const baseWellnessUrl = this.baseProviderFileServiceUrl + 'api/WellnessAttachment';

    if (this.postWellnessAttachmentUrl == null) {
      this.postWellnessAttachmentUrl = baseWellnessUrl;
    }
    if (this.getWellnessAttachmentUrl == null) {
      this.getWellnessAttachmentUrl = baseWellnessUrl + '/search';
    }
    if (this.getWellnessAttachmentAndFilesUrl == null) {
      this.getWellnessAttachmentAndFilesUrl = baseWellnessUrl + '/search/files';
    }
    if (this.getWellnessAttachmentFilesUrl == null) {
      this.getWellnessAttachmentFilesUrl = baseWellnessUrl + '/data';
    }
    if (this.getWellnessAttachmentFileUrl == null) {
      this.getWellnessAttachmentFileUrl = baseWellnessUrl + '/file';
    }    

    const baseADTUrl = this.baseProviderFileServiceUrl + 'api/AdtAttachment';

    if (this.postADTAttachmentUrl == null) {
      this.postADTAttachmentUrl = baseADTUrl;
    }
    if (this.getADTAttachmentUrl == null) {
      this.getADTAttachmentUrl = baseADTUrl + '/search';
    }
    if (this.getADTAttachmentAndFilesUrl == null) {
      this.getADTAttachmentAndFilesUrl = baseADTUrl + '/search/files';
    }
    if (this.getADTAttachmentFilesUrl == null) {
      this.getADTAttachmentFilesUrl = baseADTUrl + '/data';
    }
    if (this.getADTAttachmentFileUrl == null) {
      this.getADTAttachmentFileUrl = baseADTUrl + '/file';
    }   
  }

  public uploadReferralAttachments(formData: FormData): Observable<any> {
    this.checkUrls();
    return this.http.post<ReferralSubmissionResponse>(this.postReferralAttachmentUrl, formData, {
      reportProgress: true, observe: 'events'
    })
      .pipe(
        map(result => {
          return result;
        }),
        catchError(error => {
          return HttpResponseHelper.handleError(error);
        })
      );
  }

  public uploadAdtAttachments(formData: FormData): Observable<any> {
    this.checkUrls();
    return this.http.post<AdtSubmissionResponse>(this.postADTAttachmentUrl, formData, {
      reportProgress: true, observe: 'events'
    })
      .pipe(
        map(result => {
          return result;
        }),
        catchError(error => {
          return HttpResponseHelper.handleError(error);
        })
      );
  }

  public getReferralAttachment(memberId: string, referralId: string):Observable<ReferralAttachment[]>{
    try {
      this.checkUrls();
      const url = this.getReferralAttachmentUrl + '?memberId=' + memberId + '&referralId=' + referralId;
      return this.http.get<ReferralAttachment[]>(url)
        .pipe(
          map(data => {
            return data;
          }),
          catchError(error => {
            return HttpResponseHelper.handleError(error);
          })
        );
    }
    catch (ex) {
      // log error
      throw ex;
    }
  }

  public getReferralAttachmentAndFiles(memberId: string, referralId: string, vendorId: number, referringProviderId: string, servicingProviderId: string): Observable<ReferralAttachment> {
    try {
      this.checkUrls();
      const url = this.getReferralAttachmentAndFilesUrl + '?memberId=' + memberId + '&referralId=' + referralId + '&vendorId=' + vendorId + '&referringProviderId=' + referringProviderId + '&servicingProviderId=' + servicingProviderId;

      return this.http.get<ReferralAttachment>(url)
        .pipe(
          map(data => {
            return data;
          }),
          catchError(error => {
            return HttpResponseHelper.handleError(error);
          })
        );
    }
    catch (ex) {
      // log error
      throw ex;
    }
  }

  public getReferralAttachmentAndFilesExt(memberId: string, referralId: string, vendorId: number, referringProviderId: string, servicingProviderId: string): Observable<ReferralAttachment> {
    try {
      this.checkUrls();
      const url = this.getReferralAttachmentAndFilesExtUrl + '?memberId=' + memberId + '&referralId=' + referralId + '&vendorId=' + vendorId + '&referringProviderId=' + referringProviderId + '&servicingProviderId=' + servicingProviderId;

      return this.http.get<ReferralAttachment>(url)
        .pipe(
          map(data => {
            return data;
          }),
          catchError(error => {
            return HttpResponseHelper.handleError(error);
          })
        );
    }
    catch (ex) {
      // log error
      throw ex;
    }
  }

  public getReferralAttachmentFiles(attachmentId: number):Observable<ReferralAttachment>{
    try {
      this.checkUrls();
      const url = this.getReferralAttachmentFilesUrl + '/' + attachmentId;
      return this.http.get<ReferralAttachment>(url)
        .pipe(
          map(data => {
            return data;
          }),
          catchError(error => {
            return HttpResponseHelper.handleError(error);
          })
        );
    }
    catch (ex) {
      // log error
      throw ex;
    }
  }

  public getReferralAttachmentFile(attachmentId: number, originalFilename: string):Observable<ReferralAttachmentFile>{
    try {
      this.checkUrls();
      const url = this.getReferralAttachmentFileUrl + '/' + attachmentId + '?originalFilename=' + originalFilename;
      return this.http.get<ReferralAttachmentFile>(url)
        .pipe(
          map(data => {
            return data;
          }),
          catchError(error => {
            return HttpResponseHelper.handleError(error);
          })
        );
    }
    catch (ex) {
      // log error
      throw ex;
    }
  }

  public getReferralAttachmentExtFile(fileId: string, memberId: string, referralId: string, referringProviderId: string, servicingProviderId: string):Observable<ReferralAttachmentFile>{
    try {
      this.checkUrls();
      const url = this.getReferralAttachmentExtFileUrl + '/' + fileId 
                  + '?memberId=' + memberId 
                  + '&referralId=' + referralId 
                  + '&referringProviderId=' + referringProviderId  
                  + '&servicingProviderId=' + servicingProviderId;

      return this.http.get<ReferralAttachmentFile>(url)
        .pipe(
          map(data => {
            return data;
          }),
          catchError(error => {
            return HttpResponseHelper.handleError(error);
          })
        );
    }
    catch (ex) {
      // log error
      throw ex;
    }
  }

  public uploadWellnessAttachments(formData: FormData): Observable<any> {
    this.checkUrls();

    return this.http.post<WellnessSubmissionResponse>(this.postWellnessAttachmentUrl, formData)
    .pipe(
      map(result => {
        return result;
      }),
      catchError(error => {
        return HttpResponseHelper.handleError(error);
      })
    );
  }

  public getWellnessAttachment(memberId: string, providerId: string, providerTaxId: string, wellnessYear: number):Observable<WellnessSearchData[]>{
    try {
      this.checkUrls();
      const url = this.getWellnessAttachmentUrl + '?memberId=' + memberId + '&providerId=' + providerId + '&providerTaxId=' + providerTaxId + '&wellnessyear=' + wellnessYear;
      return this.http.get<WellnessSearchData[]>(url)
        .pipe(
          map(data => {
            return data;
          }),
          catchError(error => {
            return HttpResponseHelper.handleError(error);
          })
        );
    }
    catch (ex) {
      // log error
      throw ex;
    }
  }

  public getWellnessAttachmentFile(attachmentId: number, originalFilename: string):Observable<WellnessAttachmentFile>{
    try {
      this.checkUrls();
      const url = this.getWellnessAttachmentFileUrl + '/' + attachmentId + '?originalFilename=' + originalFilename;
      return this.http.get<WellnessAttachmentFile>(url)
        .pipe(
          map(data => {
            return data;
          }),
          catchError(error => {
            return HttpResponseHelper.handleError(error);
          })
        );
    }
    catch (ex) {
      // log error
      throw ex;
    }
  }

  public getADTAttachment(adtId: string):Observable<ADTSearchData[]>{
    try {

      // let searchData : ADTSearchData[] = [];
      // let item: ADTSearchData = new ADTSearchData();
      // item.adtAttachmentID = 1;
      // item.originalFileName = "abc.txt";
      // item.fileSize = 100;
      // item.fileStreamId = "1";
      // item.adtId = "ADT1001";
      // searchData.push(item);

      // item = new ADTSearchData();
      // item.adtAttachmentID = 2;
      // item.fileSize = 200;
      // item.fileStreamId = "2";
      // item.adtId = "ADT1002";
      // item.originalFileName = "abc1.pdf";
      // searchData.push(item);

      // return of(searchData);
      
      this.checkUrls();
      const url = this.getADTAttachmentUrl + '?adtId=' + adtId;
      return this.http.get<ADTSearchData[]>(url)
        .pipe(
          map(data => {
            return data;
          }),
          catchError(error => {
            return HttpResponseHelper.handleError(error);
          })
        );
    }
    catch (ex) {
      // log error
      throw ex;
    }
  }

  public getADTAttachmentFile(attachmentId: number, originalFilename: string):Observable<ADTAttachmentFile>{
    try {
      this.checkUrls();
      const url = this.getADTAttachmentFileUrl + '/' + attachmentId + '?originalFilename=' + originalFilename;
      return this.http.get<ADTAttachmentFile>(url)
        .pipe(
          map(data => {
            return data;
          }),
          catchError(error => {
            return HttpResponseHelper.handleError(error);
          })
        );
    }
    catch (ex) {
      // log error
      throw ex;
    }
  }
  
  public mapFileTypeIcon(fileContentType: string) {
    switch (fileContentType) {
      case 'pdf':
        return 'fa fa-file-pdf-o';
      case 'docx':
        return 'fa fa-file-word-o';
      case 'csv':
        return 'fa fa-file-excel-o';
      case 'file':
        return 'fa fa-file-text-o ';
      case 'img':
        return 'fa fa-file-image-o';
      default:
        return 'fa fa-file-text-o';
    }
  }

  public isImageFileType(contentType: string): boolean {
    return contentType === 'jpeg' || contentType === 'jpg' || contentType === 'tif' || contentType === 'png' || contentType === 'bmp';
  }
}
