import { Component, Input, OnInit, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-alert-success',
  templateUrl: './alert-success.component.html',
  styleUrls: ['./alert-success.component.scss']
})
export class AlertSuccessComponent implements OnInit {

  public displayAlert = false;
  public subjectSuccessId: string;
  public contentSuccessId: string;

  @Input() subjectMessage: string;
  @Input() contentMessage: string;
  @Input() uniqueID: string;

  constructor(@Inject(DOCUMENT) private document: Document) { }

  ngOnInit() {
    this.displayAlert = this.subjectMessage.length > 0 || this.contentMessage.length > 0;
    this.subjectSuccessId = (!this.uniqueID || this.uniqueID.trim().length === 0) ? "subject-success" : "subject-success-" + this.uniqueID;
    this.contentSuccessId = (!this.uniqueID || this.uniqueID.trim().length === 0) ? "content-success" : "content-success-" + this.uniqueID;
  }

  ngAfterViewInit(): void {
    this.setContentMessages();
  }

  private setContentMessages() {
    let elSubject = this.document.getElementById(this.subjectSuccessId);
    if (this.subjectMessage) {
      elSubject.innerHTML = this.subjectMessage;
      elSubject.style.display = 'inline';
    } else {
      elSubject.style.display = 'none';
    }

    this.document.getElementById(this.contentSuccessId).innerHTML = this.contentMessage
  }
}
