import { NgModule  } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TableModule } from 'primeng/table';
import { TooltipModule } from 'primeng/tooltip';
import { DropdownModule } from 'primeng/dropdown';
import { FileUploadModule } from 'primeng/fileupload';
import { DialogModule } from 'primeng/dialog';
import { MultiSelectModule } from 'primeng/multiselect';
import { SidebarModule } from 'primeng/sidebar';
import { CheckboxModule } from 'primeng/checkbox';
import { AutoCompleteModule } from 'primeng/autocomplete';

import { PortalUIComponentsModule } from '@caloptima/portal-ui-components';
import { InputMaskModule } from 'primeng/inputmask';
//import { TextMaskModule } from 'angular2-text-mask'; TODO

import { AddCollectionDialogComponent } from './dialogs/add-collection-dialog/add-collection-dialog.component';
import { AddProviderDialogComponent } from './dialogs/add-provider-dialog/add-provider-dialog.component';
import { AddDiagnosisDialogComponent } from './dialogs/add-diagnosis-dialog/add-diagnosis-dialog.component';
import { AddUserDialogComponent } from './dialogs/add-user-dialog/add-user-dialog.component';
import { ProviderInformationDialogComponent } from './dialogs/provider-information-dialog/provider-information-dialog.component';
import { RemoveUserDialogComponent } from './dialogs/remove-user-dialog/remove-user-dialog.component';
import { RenameCollectionDialogComponent } from './dialogs/rename-collection-dialog/rename-collection-dialog.component';

import { PaginatorComponent } from './paginator/paginator.component';
import { IdleTimeoutDialogComponent } from './dialogs/idle-timeout-dialog/idle-timeout-dialog.component';
import { PleaseWaitDialogComponent } from './dialogs/please-wait-dialog/please-wait-dialog.component';
import { LookupDateComponent } from './lookup-date/lookup-date.component';
import { ButtonToggleComponent } from './button-toggle/button-toggle.component';
import { LookupNumberComponent } from './lookup-number/lookup-number.component';
import { DateMaskDirective } from './directives/date-mask.directive';
import { FutureDateValidatorDirective } from './directives/future-date-check.directive';
import { ActiveEllipsisDirective } from './directives/active-ellipsis.directive';
import { AutofocusDirective } from './directives/autofocus.directive';
import { NameOnlyDirective } from './directives/name-only.directive';
import { NumberOnlyDirective } from './directives/number-only.directive';

import { SmallScreenViewerComponent } from './small-screen-viewer/small-screen-viewer.component';
import { SessionTimeoutDialogComponent } from './dialogs/session-timeout-dialog/session-timeout-dialog.component';
import { AlertInfoComponent } from './alerts/alert-info/alert-info.component';
import { AlertDangerComponent } from './alerts/alert-danger/alert-danger.component';
import { AlertWarningComponent } from './alerts/alert-warning/alert-warning.component';
import { AlertSuccessComponent } from './alerts/alert-success/alert-success.component';
import { UserVerificationDialogComponent } from './dialogs/user-verification-dialog/user-verification-dialog.component';
import { RefSubmissionCancelDialogComponent } from './dialogs/ref-submission-cancel-dialog/ref-submission-cancel-dialog.component';
import { CityLookupComponent } from './city-lookup/city-lookup.component';
import { TrainingAlertDialogComponent } from './dialogs/training-alert-dialog/training-alert-dialog.component';
import { ReferralAttachmentDialogComponent } from './dialogs/referral-attachment-dialog/referral-attachment-dialog.component';
import { SaveChangesAlertDialogComponent } from './dialogs/save-changes-alert-dialog/save-changes-alert-dialog.component';
import { UserPermissionDialogComponent } from './dialogs/user-permission-dialog/user-permission-dialog.component';
import { UserPcpAgreementComponent } from './dialogs/user-permission-dialog/user-pcp-agreement/user-pcp-agreement.component';
import { UserPositionAndAuthorizedDialogComponent } from './dialogs/user-permission-dialog/user-position-and-authorized-dialog/user-position-and-authorized-dialog.component';
import { UserApproveDeclineDialogComponent } from './dialogs/user-approve-decline-dialog/user-approve-decline-dialog.component';
import { UserDeclineDialogComponent } from './dialogs/user-decline-dialog/user-decline-dialog.component';
import { ConfirmDialogComponent } from './dialogs/confirm-dialog/confirm-dialog.component';
import { UserOrganizationDialogComponent } from './dialogs/user-organization-dialog/user-organization-dialog.component';
import { CreatePasswordComponent } from './create-password/create-password.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { VerifyEmailComponent } from './verify-email/verify-email.component';
import { PasswordResetDialogComponent } from './dialogs/password-reset-dialog/password-reset-dialog.component';
import { UserEditDialogComponent } from './dialogs/user-edit-dialog/user-edit-dialog.component';
import { UserChangePasswordDialogComponent } from './dialogs/user-change-password-dialog/user-change-password-dialog.component';
import { UserChangeSecurityDialogComponent } from './dialogs/user-change-security-dialog/user-change-security-dialog.component';
import { CreateSecurityQuestionsComponent } from './create-security-questions/create-security-questions.component';
import { ProviderSearchComponent } from './provider-search/provider-search.component';
import { TabViewModule } from 'primeng/tabview';
import { MessageModule } from 'primeng/message';
import { RadioButtonModule } from 'primeng/radiobutton';
import { DisclaimerLinksComponent } from './disclaimer-links/disclaimer-links.component';
import { BrowserRefreshDialogComponent } from './dialogs/browser-refresh-dialog/browser-refresh-dialog.component';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ContinueDialogComponent } from './dialogs/continue-dialog/continue-dialog.component';
import { VerifyIdentityPasscodeComponent } from './verify-identity-passcode/verify-identity-passcode.component';
import { VerifyIdentityComponent } from './verify-identity/verify-identity.component';
import { LoginErrrorsComponent } from './login-errrors/login-errrors.component';
import { UserPermissionPromptComponent } from './dialogs/user-permission-dialog/user-permission-prompt/user-permission-prompt.component';
import { HIFMemberDataDialogComponent } from './dialogs/hif-member-data-dialog/hif-member-data-dialog.component';
import { HIFMemberDataComponent } from './hif-member-data/hif-member-data.component';

@NgModule({
  declarations: [
    AddCollectionDialogComponent,
    AddDiagnosisDialogComponent,
    AddProviderDialogComponent,
    AddUserDialogComponent,
    SessionTimeoutDialogComponent,
    PaginatorComponent,
    ProviderInformationDialogComponent,
    RemoveUserDialogComponent,
    RenameCollectionDialogComponent,
    IdleTimeoutDialogComponent,
    PleaseWaitDialogComponent,
    LookupDateComponent,
    LookupNumberComponent,
    ButtonToggleComponent,
    SmallScreenViewerComponent,
    AlertInfoComponent,
    AlertDangerComponent,
    AlertWarningComponent,
    AlertSuccessComponent,
    UserVerificationDialogComponent,
    RefSubmissionCancelDialogComponent,
    CityLookupComponent,
    TrainingAlertDialogComponent,
    ReferralAttachmentDialogComponent,
    SaveChangesAlertDialogComponent,
    UserPermissionDialogComponent,
    UserPcpAgreementComponent,
    UserPermissionPromptComponent,
    UserPositionAndAuthorizedDialogComponent,
    UserApproveDeclineDialogComponent,
    UserDeclineDialogComponent,
    ConfirmDialogComponent,
    UserOrganizationDialogComponent,
    UserEditDialogComponent,
    ChangePasswordComponent,
    VerifyEmailComponent,
    PasswordResetDialogComponent,
    CreatePasswordComponent,
    UserChangePasswordDialogComponent,
    UserChangeSecurityDialogComponent,
    CreateSecurityQuestionsComponent,
    ProviderSearchComponent,
    DisclaimerLinksComponent,
    BrowserRefreshDialogComponent,
    ContinueDialogComponent,
    VerifyIdentityPasscodeComponent,
    VerifyIdentityComponent,
    LoginErrrorsComponent,
    DateMaskDirective,
    FutureDateValidatorDirective,
    ActiveEllipsisDirective,
    AutofocusDirective,
    NameOnlyDirective,
    NumberOnlyDirective,
    HIFMemberDataDialogComponent,
    HIFMemberDataComponent    
  ],
  imports: [
    FormsModule,
    CommonModule,
    PortalUIComponentsModule,
    // TextMaskModule, TODO
    InputMaskModule,
    AutoCompleteModule,
    CheckboxModule,
    DialogModule,
    DropdownModule,
    MultiSelectModule,
    TabViewModule,
    TableModule,
    FileUploadModule,
    TooltipModule,
    SidebarModule,
    RouterModule,
    MessageModule,
    RadioButtonModule,
    ConfirmDialogModule
  ],
  exports: [
    AddCollectionDialogComponent,
    AddDiagnosisDialogComponent,
    AddProviderDialogComponent,
    AddUserDialogComponent,
    SessionTimeoutDialogComponent,
    PaginatorComponent,
    ProviderInformationDialogComponent,
    RemoveUserDialogComponent,
    RenameCollectionDialogComponent,
    IdleTimeoutDialogComponent,
    PleaseWaitDialogComponent,
    LookupDateComponent,
    LookupNumberComponent,
    ButtonToggleComponent,
    SmallScreenViewerComponent,
    AlertInfoComponent,
    AlertDangerComponent,
    AlertWarningComponent,
    AlertSuccessComponent,
    UserVerificationDialogComponent,
    RefSubmissionCancelDialogComponent,
    CityLookupComponent,
    TrainingAlertDialogComponent,
    ReferralAttachmentDialogComponent,
    SaveChangesAlertDialogComponent,
    UserPermissionDialogComponent,
    UserPcpAgreementComponent,
    UserPermissionPromptComponent,
    UserPositionAndAuthorizedDialogComponent,
    UserApproveDeclineDialogComponent,
    UserDeclineDialogComponent,
    ConfirmDialogComponent,
    UserOrganizationDialogComponent,
    UserEditDialogComponent,
    ChangePasswordComponent,
    VerifyEmailComponent,
    PasswordResetDialogComponent,
    CreatePasswordComponent,
    UserChangePasswordDialogComponent,
    UserChangeSecurityDialogComponent,
    CreateSecurityQuestionsComponent,
    ProviderSearchComponent,
    DisclaimerLinksComponent,
    BrowserRefreshDialogComponent,
    ContinueDialogComponent,
    VerifyIdentityPasscodeComponent,
    VerifyIdentityComponent,
    LoginErrrorsComponent,
    AutofocusDirective,
    DateMaskDirective,
    FutureDateValidatorDirective,
    ActiveEllipsisDirective,
    NameOnlyDirective,
    NumberOnlyDirective,
    FileUploadModule,
    HIFMemberDataDialogComponent,
    HIFMemberDataComponent
  ]
})
export class AppComponentsModule { }
