import { Component, OnInit, Input, Inject, AfterViewInit, SecurityContext } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-alert-danger',
  templateUrl: './alert-danger.component.html',
  styleUrls: ['./alert-danger.component.scss']
})
export class AlertDangerComponent implements OnInit, AfterViewInit {
  public displayAlert = false;
  public subjectDangerId: string;
  public contentDangerId: string;

  @Input() subjectMessage: string;
  @Input() contentMessage: string;
  @Input() uniqueID: string;

  constructor(@Inject(DOCUMENT) private document: Document,
  private sanitizer: DomSanitizer) { }

  ngOnInit() {
    this.displayAlert = this.subjectMessage.length > 0 || this.contentMessage.length > 0;
    this.subjectDangerId = (!this.uniqueID || this.uniqueID.trim().length === 0) ? "subject-danger" : "subject-danger-" + this.uniqueID;
    this.contentDangerId = (!this.uniqueID || this.uniqueID.trim().length === 0) ? "content-danger" : "content-danger-" + this.uniqueID;
  }

  ngAfterViewInit(): void {
    this.setContentMessages();
  }

  public setContentMessages() {
    let elSubject = this.document.getElementById(this.subjectDangerId);
    if (this.subjectMessage) {
      elSubject.innerHTML = this.subjectMessage;
      elSubject.style.display = 'inline';
    } else {
      elSubject.style.display = 'none';
    }

    this.document.getElementById(this.contentDangerId).innerHTML = this.sanitizer.sanitize(SecurityContext.HTML, this.contentMessage);
  }
}
