import { Injectable } from "@angular/core";
@Injectable()
export class Messages {
  public DefaultEulaContent: string = 'Please wait while the license content is retrieved.';
  public DeclinedMessage: string = 'To continue creating your user account, you must accept the terms of the license agreement.';
  public PhonePasscode: string = 'Passcode (6 digits)';
  public EmailPasscode: string = 'Passcode (6 digits)';
  public MinutesLabel: string = ' minutes.';
  public SecondsLabel: string = ' seconds.';
  public ProviderSearchResults: string = ' results for ';
  public ProviderSearchError: string = 'There was an error searching providers: ';
  public ProviderNameError: string = 'You must enter a first and last name.';
  public ProviderTINError: string = 'You must enter a Tax Identification Number.';
  public ProviderTINFormatError: string = 'You must enter a 9-digit Tax Identification Number.';
  public ProviderGroupNameError: string = 'You must enter a group / facility name.';
  public ProviderSearchHelpTooltip: string = 'If you need further assistance, please contact our Help Desk at <br/>(800) 888-8888.';
  public ProviderSearchPractitionerTabTooltip: string = 'Individual Providers: Physicians, Mid Level Practitioners, Podiatrists, Psychologist, Chiropractors, Therapists';
  public ProviderSearchGroupTabTooltip: string = 'Medical Groups, Clinics, FQHC, Pharmacies, Hospitals, DME';
  public SeveritySuccess: string = 'success';
  public SeverityInfo: string = 'info';
  public SeverityError: string = 'error';
  public SeverityWarn: string = 'warn';
  public TitleSaveChanges: string = 'Save Changes';
  public TitleConfirmDeactivation: string = 'Confirm Deactivation';
  public TitleNoResults: string = 'Attention';
  public ContentSaveUser: string = 'Do you want to save the changes to ';
  public ContentDeactivateUser: string = 'Are you sure you want to deactivate ';
  public ContentMissingField: string = 'Required fields are missing';
  public MessageNoProviders: string = 'No providers found';
  public MessageCollectionModified: string = 'You have made changes to a collection that has not been saved.  Do you wish to save them?';
  public MessageNoCollectionProviders: string = 'You must add providers to the collection before it can be saved.';
  public MesssgeNoCollection: string = 'Select Collection';
  public MessageProvidersSelectedNotAdded: string = 'Add selected providers before closing?';
  public MessageTINInvalid: string = 'Please enter a valid TIN number';
  public MessageUserApproved: string = 'An email has been sent to the user to set up their credentials';
  public MessageFactorsInvalid: string = 'One or more questions were answered incorrectly.';
  public MessageMembersNoResultsFound: string = 'No Records Found';
  public MessageIncorrectProviderNote: string = "Choosing the incorrect {0} will result in having to start the referral over."
  public SpecialPhraseResetSuccess: string = 'Password sent!';
  public SpecialPhraseResetFail: string = 'Password reset email could not be sent.';
  public ButtonRemove: string = 'Remove';
  public ButtonNo: string = 'No';
  public ButtonYes: string = 'Yes';
  public SpecialPhraseComplexity: string = 'Your password must be at least eight (8) characters and must contain all of the following:';
  public RememberThisDevice: string = 'By clicking here, you will not need to reauthorize this computer or device in the future.';
  public UserNotAssociatedToMemberPcp: string = 'The information you are requesting is restricted to PCPs only.';
  public ServiceProviderDistanceSubjectAlert: string = 'Provider is far away.';
  public ServiceProviderDistanceMessageAlert: string = 'Look for a provider within 15 miles or 30 minutes to the member.';
  public ClosedForNewPatientsSubjectAlert = 'This provider you have requested is currently not accepting new patients.';
  public ClosedForNewPatientsMessageAlert = 'Please document the existing relationship with this provider and the last appointment date with this provider in the next step under Clinical Indications.';
  public ClosedForNewPatientsShortAlert = 'Provider not accepting new patients. Document relationship with member under Clinical Indications.';

  public ToasterLife: 5000;
}
