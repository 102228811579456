export class UserPermissionTypeMap {
  public static permissionType: any[] = [
    { value: 'ENTADM', label: 'Manage User Permission', icon: 'manage-user-icon.png', desc: 'LOA - Manage User Permission' },
    { value: 'PRVADM', label: 'Manage User Permission', icon: 'manage-user-icon.png', desc: 'EA - Manage User Permission' },
    { value: 'PRVCLR', label: 'View Claims History', icon: 'claims-history-icon.png', desc: 'Claims | View Claims History' },
    { value: 'PRVCHR', label: 'View Condition History', icon: 'condition-history-icon.png', desc: 'Condition | View Condition History' },
    { value: 'PRVELR', label: 'View Member Eligibility', icon: 'eligibility-icon.png', desc: 'Eligibility | View Member Eligibility' },
    { value: 'PRVLAR', label: 'View Labs', icon: 'labs-icon.png', desc: 'Labs | View Labs' },
    { value: 'PRVPHR', label: 'View Medications', icon: 'medications-icon.png', desc: 'Pharmacy | View Medications' },
    { value: 'PRVRER', label: 'View Referrals', icon: 'referrals-icon.png', desc: 'Referrals | View Referrals' },
    { value: 'PRVREM', label: 'Submit Referrals', icon: 'submit-referrals-icon.png', desc: 'Referrals | Submit Referrals' },
    { value: 'PRVRPR', label: 'View Reports', icon: 'reports-icon.png', desc: 'Reports | View Reports' }
  ];

  public static mapPermissionTypeToIcon(permissionCode: string): string {
    let permissonIcon = "";
    UserPermissionTypeMap.permissionType.forEach(item => {
      if (item.value == permissionCode) {
        permissonIcon = item.icon;
      }
    })
    return permissonIcon;
  }
}
