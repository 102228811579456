import { OnInit, Input, AfterViewInit, Inject, Component } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-alert-info',
  templateUrl: './alert-info.component.html',
  styleUrls: ['./alert-info.component.scss']
})
export class AlertInfoComponent implements OnInit, AfterViewInit {
  public displayAlert = false;
  public subjectInfoId: string;
  public contentInfoId: string;

  @Input() subjectMessage: string;
  @Input() contentMessage: string;
  @Input() uniqueID: string;

  constructor(@Inject(DOCUMENT) private document: Document) { }

  ngOnInit() {
    this.displayAlert = this.subjectMessage.length > 0 || this.contentMessage.length > 0;
    this.subjectInfoId = (!this.uniqueID || this.uniqueID.trim().length === 0) ? "subject-info" : "subject-info-" + this.uniqueID;
    this.contentInfoId = (!this.uniqueID || this.uniqueID.trim().length === 0) ? "content-info" : "content-info-" + this.uniqueID;
  }

  ngAfterViewInit(): void {
    this.setContentMessages();
  }

  private setContentMessages() {
    let elSubject = this.document.getElementById(this.subjectInfoId);
    if (this.subjectMessage) {
      elSubject.innerHTML = this.subjectMessage;
      elSubject.style.display = 'inline';
    } else {
      elSubject.style.display = 'none';
    }

    this.document.getElementById(this.contentInfoId).innerHTML = this.contentMessage
  }
}
