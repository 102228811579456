import { Component, OnInit, Input, Inject, AfterViewInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-alert-warning',
  templateUrl: './alert-warning.component.html',
  styleUrls: ['./alert-warning.component.scss']
})
export class AlertWarningComponent implements OnInit, AfterViewInit {
  public displayAlert = false;
  public subjectWarningId: string;
  public contentWarningId: string;

  @Input() subjectMessage: string;
  @Input() contentMessage: string;
  @Input() uniqueID: string;

  constructor(@Inject(DOCUMENT) private document: Document) { }

  ngOnInit() {
    this.displayAlert = this.subjectMessage.length > 0 || this.contentMessage.length > 0;
    this.subjectWarningId = (!this.uniqueID || this.uniqueID.trim().length === 0) ? "subject-warning" : "subject-warning-" + this.uniqueID;
    this.contentWarningId = (!this.uniqueID || this.uniqueID.trim().length === 0) ? "content-warning" : "content-warning-" + this.uniqueID;
  }

  ngAfterViewInit(): void {
    this.setContentMessages();
  }

  private setContentMessages() {
    let elSubject = this.document.getElementById(this.subjectWarningId);
    if (this.subjectMessage) {
      elSubject.innerHTML = this.subjectMessage;
      elSubject.style.display = 'inline';
    } else {
      elSubject.style.display = 'none';
    }

    this.document.getElementById(this.contentWarningId).innerHTML = this.contentMessage
  }
}
