import { Component, Input, OnInit, OnChanges } from '@angular/core';
import { Constants } from './../../app.constants';
import { SessionService } from '../../services/session.service';

@Component({
  selector: 'app-disclaimer-links',
  templateUrl: './disclaimer-links.component.html',
  styleUrls: ['./disclaimer-links.component.scss']
})
export class DisclaimerLinksComponent implements OnInit {
  @Input() isSmallFont = false;
  public PortalAccessAgreementUrl = '';
  public PortalAccessPolicyUrl = '';
  public PortalUserAgreementUrl = '';
  public caloptimahealthguidelinesUrl = 'https://caloptimahealth.access.mcg.com/index';

  constructor(
    private constants: Constants,
    private sessionService: SessionService
  ) { }

  ngOnInit() {
    this.setDisclaimerLinks();
  }

  public get IsLoggedInUser():boolean {  
      return this.sessionService.currentPermission != null;
  }

  private setDisclaimerLinks() {
    this.constants.CalOptimaOrgUrlSubject().subscribe(
      result => {
        this.PortalAccessAgreementUrl = result + this.constants.PortalAccessAgreementPath;
        this.PortalAccessPolicyUrl = result + this.constants.PortalAccessPolicyPath;
      });
  }
}
